module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#EC4C4C","showSpinner":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://singaporedreamin.com"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150925532-1","head":false,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"singapore-dreamin","short_name":"singapore-dreamin","start_url":"/","background_color":"#EC4C4C","theme_color":"#EC4C4C","display":"minimal-ui","icon":"src/images/favicon.png"},
    }]
